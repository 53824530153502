<template>
  <v-container fluid>
    <v-card>
      <v-card-title>{{ $t("m.master.title") }}</v-card-title>
      <v-card-subtitle>{{ $t("m.master.subtitle") }}</v-card-subtitle>
      <v-card-text v-if="isMasterUrlEmpty">
        <v-row justify="center">
          <v-col cols="auto" align-self="center">
            <v-progress-circular
              color="primary"
              :indeterminate="isMasterUrlEmpty"
            ></v-progress-circular>
          </v-col>
          <v-col cols="auto" align-self="center">{{
            $t("m.master.creating")
          }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-col>
          <v-row justify="center">
            <VueQr :dotScale="0.5" :text="masterUrl" />
          </v-row>
          <v-row justify="center">
            <v-col align-self="center" cols="auto">
              <a :href="masterUrl">{{ masterUrl }}</a>
            </v-col>
            <v-col align-self="center" cols="auto">
              <v-btn icon @click="download">
                <v-icon>mdi-download-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
    <div id="qrCodeCard" ref="qrCodeCard" style="opacity: 0">
      <v-card>
        <v-card-title>{{ $t("m.master.qrCodeCard.title") }}</v-card-title>
        <v-card-text>
          <v-col>
            <v-row justify="center">
              <VueQr :dotScale="0.5" :text="masterUrl" />
            </v-row>
            <v-row justify="center">
              <v-col align-self="center" cols="auto">
                <a :href="masterUrl">{{ masterUrl }}</a>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </div>
    <a
      v-show="false"
      ref="doDownload"
      :href="qrCodeUrl"
      download="master-qr-code.png"
      >click</a
    >
  </v-container>
</template>

<script>
import html2canvas from 'html2canvas'

import { encodeCampaignInfo } from '@/helpers/campaign-info'
import { shrinkUrl } from '@/helpers/referral'

import VueQr from 'vue-qr'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    VueQr
  },
  data: () => ({
    masterUrl: '',
    qrCodeUrl: ''
  }),
  computed: {
    appSociallyColor () {
      return '#FFD54F'
    },
    isMasterUrlEmpty () {
      return this.masterUrl === ''
    }
  },
  methods: {
    async download () {
      const getUrl = (canvas) => {
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            resolve(URL.createObjectURL(blob))
          })
        })
      }

      const canvas = await html2canvas(this.$refs.qrCodeCard, {
        onclone (cloned) {
          cloned.getElementById('qrCodeCard').style.opacity = 1.0
        }
      })
      this.qrCodeUrl = await getUrl(canvas)
      this.$nextTick(() => {
        this.$refs.doDownload.click()
      })
    }
  },
  async created () {
    const ownerId = this.$route.params.ownerId
    const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

    const absoluteUrl = window ? window.location.origin : ''
    const campaignInfo = encodeCampaignInfo({ ownerId, ownerScenarioId, campaignId: '001', campaignType: 'master' })

    // createQRCode()

    this.masterUrl = await shrinkUrl(`${absoluteUrl}/jobs/${ownerId}/${ownerScenarioId}?campaignInfo=${campaignInfo}`)
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_MASTER_QR_CODE)
  }
}
</script>
